import { h } from "preact"
import { BaseComponentType } from "../types/common"

type ColSizeType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

type ColProps = {
    xs?: ColSizeType,
    sm?: ColSizeType, 
    md?: ColSizeType, 
    lg?: ColSizeType, 
    xl?: ColSizeType, 
    xxl?: ColSizeType,
    className?: string
} & BaseComponentType

export default function Col({ children, className = "", ...props }: ColProps){

    // @ts-ignore TODO: fix type
    const columnSizes = Object.keys(props).map(sizeName => `col-${sizeName}-${props[sizeName]}`).join(' ')

    return(
        <div className={`col ${columnSizes} ${className}`}>
            {children}
        </div>
    )
}