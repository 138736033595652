import { h } from "preact";

// funny time hack :) Nope, javascript can't do this native
const formatTimeNumberZero = (sec: number): string => {
    const prepend = sec < 10 ? '0' : '';
    return `${prepend}${sec}`;
}

const parseTimeFromDate = (date: Date): string => {
    return `${date.getDate()}. ${date.getMonth()+1}. ${date.getFullYear()} 
        ${date.getHours()}:${formatTimeNumberZero(date.getMinutes())}:${formatTimeNumberZero(date.getSeconds())}`;
}

type PingTimeProps = {
    time?: string
}

export default function PingTime({ time }: PingTimeProps){
    return(
        <div>Čas pingnutí: {time ? parseTimeFromDate(new Date(time)) : "-"}</div>
    )
} 