import { h } from "preact";
import { BaseComponentType } from "../../types/common";

type CardHeaderProps = {
    className?: string
} & BaseComponentType

export default function CardHeader({ children, className = "" }: CardHeaderProps){
    return(
        <div className={`card-header ${className}`}>
            {children}
        </div>
    )
}