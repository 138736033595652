import { h } from "preact";
import { BaseComponentType } from "../../types/common";

type CardProps = {
    className?: string
} & BaseComponentType

export default function Card({ children, className = "" }: CardProps){
    return(
        <div className={`card ${className}`}>
            {children}
        </div>
    )
}