import { h } from "preact";
import { BaseComponentType } from "../../types/common";

type CardBodyProps = {
    className?: string
} & BaseComponentType

export default function CardBody({ children, className = "" }: CardBodyProps){
    return(
        <div className={`card-body ${className}`}>
            {children}
        </div>
    )
}