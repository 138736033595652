import { ColumnType } from "../types";
import { h } from "preact";

import TRow from './TRow';

type THeadProps = {
    columns: ColumnType[]
}

export default function THead({ columns }: THeadProps){
    
    return (
        <thead>
            <tr>
                {columns.map(column => <TRow key={column.key} label={column.label} />)}
            </tr>
        </thead>
    )
} 