import { h } from "preact"
import { ColorsType } from "../types/common"

type BadgeProps = {
   label: string | number
   color: ColorsType
   className?: string
   rounded?: boolean
}

export default function Badge({ label, color, className = "", rounded }: BadgeProps) {
   return (
      <span className={`badge bg-${color} ${className} ${rounded ? "rounded-pill" : ""}`}>
         {label}
      </span>
   )
}
