import { ColumnType } from "../types"
import { h } from "preact";

type TRowProps = Pick<ColumnType, "label">

export default function TRow({ label }: TRowProps){
    return(
        <th>
            {label}
        </th>
    )
}