import { h } from "preact";
import { ColumnType } from "../types";
import Cell, { CellProps } from "./Cell";

export type RowType = {
    columns: ColumnType[],
    cells: Object,
}

export default function Row({ cells, columns }: RowType){

    const defFormatter = (cell: any, row: any) => cell;

    return(
        <tr>
            {/* @ts-ignore TODO type*/}
            {columns.map(cell => <Cell key={cell.key} label={cells[cell.key]} row={cells} formatter={cell.formatter || defFormatter} />)}
        </tr>
    )
}