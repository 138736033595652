import { Fragment, h } from "preact"
import { useState, useEffect } from "preact/hooks"
import { getPingatorResults } from "./api/fetch"
import PingTable from "./sections/Tables/PingAddreses"
import Row from "./components/Row"
import Col from "./components/Col"

import PortAddresses from "./sections/Tables/PortAddresses"
import { PingatorResultType } from "./api/types"

export default function App() {
   const [pingatorData, setPingatorData] = useState<PingatorResultType | null>(null)

   const getPings = async () => {
      const pings = await getPingatorResults()
      setPingatorData(pings)
   }

   useEffect(() => {
      getPings()

      const interval = setInterval(() => {
         getPings()
      }, 5000)

      return () => clearInterval(interval)
   }, [])

   return (
      <Fragment>
         <div className="container-fluid mt-2">
            <Row>
               <Col md={12} lg={6}>
                  <Row>
                     <Col sm={12} className="mb-2">
                        <PingTable
                           name="Pingátor crn"
                           pings={pingatorData?.pingAddresses?.results || []}
                           date={pingatorData?.pingAddresses?.time || ""}
                        />
                     </Col>
                  </Row>
               </Col>
               <Col md={12} lg={6}>
                  <Row>
                     <Col sm={12} className="mb-2">
                        <PortAddresses
                           name="Port test crn"
                           pings={pingatorData?.portAddresses?.results || []}
                           date={pingatorData?.portAddresses?.time || ""}
                        />
                     </Col>
                     <Col sm={12}>
                        <PingTable
                           name="Pingátor okolí"
                           pings={pingatorData?.worldAddresses?.results || []}
                           date={pingatorData?.worldAddresses?.time || ""}
                        />
                     </Col>
                  </Row>
               </Col>
            </Row>
         </div>
      </Fragment>
   )
}
