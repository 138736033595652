import { h } from "preact";

export type CellProps = {
    label: string,
    row: Object,
    formatter: (cell: any, row: Object) => h.JSX.Element | string
}

export default function Cell({ label, row, formatter }: CellProps){
    return(
        <td>{formatter(label, row)}</td>
    )
}