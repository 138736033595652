import { h } from "preact"
import { BaseComponentType } from "../types/common"

type RowProps = BaseComponentType

export default function Row({ children }: RowProps){
    return(
        <div className="row">
            {children}
        </div>
    )
}