import axios from "axios"

import { PingatorResultType } from "./types"

export const getPingatorResults = async () => {
   const result = await axios.get<PingatorResultType>(
      process.env.PREACT_APP_API_URL ?? "http://localhost:3005/",
   )

   return result.data
}
