import { h } from "preact"
import { ColorsType } from "../types/common"
import Badge from "./Badge"

type SeverityBadgeProps = {
   severity: number
}

/*const colors: { [key: number]: ColorsType } = {
   0: "info",
   1: "success",
   2: "primary",
   3: "warning",
   4: "danger",
}*/

const getBadgeColor = (severity: number): ColorsType => {
   const color = "secondary" //colors[severity]
   if (color) {
      return color
   }

   return "light"
}

export default function SeverityBadge({ severity }: SeverityBadgeProps) {
   return <Badge color={getBadgeColor(severity)} label={severity} />
}
