import { PingResultType } from "../../api/types"
import Table from "../../components/Table"
import { ColumnType } from "../../components/Table/types"
import { h } from "preact"
import { Card, CardHeader, CardBody } from "../../components/Card"
import PingTime from "../../components/PingTime"
import Badge from "../../components/Badge"
import SeverityBadge from "../../components/SeverityBadge"

const colDef: ColumnType[] = [
   {
      key: "ip",
      label: "IP",
   },
   {
      key: "label",
      label: "Popis",
   },
   {
      key: "severity",
      label: "Severity",
      formatter: (cell: number) => <SeverityBadge severity={cell} />,
   },
   {
      key: "ping",
      label: "Odezva [ms]",
      formatter: (cell: any) =>
         cell !== undefined ? (
            String(Number.parseInt(cell))
         ) : (
            <Badge label="Offline" color="danger" />
         ),
   },
]

type PingTableProps = {
   pings: PingResultType[]
   date: string
   name: string
}

export default function PingAddresses({ pings, date, name }: PingTableProps) {
   return (
      <Card>
         <CardHeader className="d-flex justify-content-between">
            <div>{name}</div>
            <div>
               <PingTime time={date} />
            </div>
         </CardHeader>
         <CardBody className="p-0">
            <Table columns={colDef} data={pings} keyField="address" className="mb-0" />
         </CardBody>
      </Card>
   )
}
