import { ColumnType } from "../types";
import { h } from "preact";
import Row from "./Row";

type TBodyProps = {
    columns: ColumnType[],
    data: [],
    keyField: string
}

export default function TBody({ columns, data, keyField }: TBodyProps){
    return (
        <tbody>
            {data.map((row, idx) => <Row key={row[keyField]} cells={row} columns={columns} /> )}
        </tbody>
    )
}