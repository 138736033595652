import { h } from "preact";

import TBody from "./TBody";
import THead from "./THead";
import { ColumnType } from "./types";

type TableProps = {
    columns: ColumnType[],
    data: any,
    keyField: string,
    className?: string
}

export default function Table({
    columns,
    data,
    keyField,
    className = "",
}: TableProps){
    return (
        <table className={`table table-striped table-sm table-hover ${className}`}>
            <THead columns={columns} />
            <TBody columns={columns} data={data} keyField={keyField} />
        </table>
    )
}